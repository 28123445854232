import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApplicationConstants } from "../models/ApplicationConstants";
import { IAccount } from "../models/IAccount";
import { IAttachment } from "../models/IAttachment";
import { IContact } from "../models/IContact";
import { ICountry } from "../models/ICountry";
import { IDashboardDataRequest } from "../models/IDashboardDataRequest";
import { IDealType } from "../models/IDealType";
import { IKeyValue } from "../models/IKeyValue";
import { IOpportunityDeal } from "../models/IOpportunityDeal";
import { IProductType } from "../models/IProductType";
import { IUserDetail } from "../models/IUserDetail";
import { IVertical } from "../models/IVertical";
import { PageMode } from "../models/PageMode";
import { SessionStorageConsts } from "../models/SessionStorageConsts";
import { IPageState } from "../pages/pricingprogram/common/state/IPageState";
import { ApiConstants } from "./ApiConstants";
import { BaseService } from "./BaseService";
import { IUserRoleDetail } from "../models/IUserRoleDetail";
import { IDataSaveResponse } from './../models/IDataSaveResponse';
import { IValidateOpportunityResponse } from "../models/IValidateOpportunityResponse";
import { IAddOpportunityAccountToCaseRequest } from "../models/IAddOpportunityAccountToCaseRequest";
import { IValidateCaseAndResellerRequest } from "../models/IValidateCaseAndResellerRequest";
import { IOpportunityDealProductSku } from "../models/IOpportunityDealProductSku";
import { getDistinctUserRole, getLoggedinUserRoleDetails, getUserRole, IsInternalPricingProgram } from "../components/shared/common/util";
import { ICustomerVertical } from "../models/ICustomerVertical";
import { IUserVoice } from "../models/IUserVoice";

import { IUnmanagedCustomer } from "../models/IUnmanagedCustomer"
import { IUnmanagedCustomerResult } from "../models/IUnmanagedCustomerResult"
import { IIndustryDropdown } from "../models/IIndustryDropdown";
import { IPricingProgramDuplicateDeal } from "../models/IPricingProgramDuplicateDeal";
import { IDealQuantityThresholds } from "../models/IDealQuantityThresholds";
import { IDealExtensionRequest } from "../models/IDealExtensionRequest";
import { IDealCopyRequest } from "../models/IDealCopyRequest";
import { IDealActionRequest } from "../models/IDealActionRequest";
import { IOpportunityDealDetails } from "../models/IOpportunityDealDetails";
import { IBidGridDeal } from "../models/IBidGridDeal";
import { IPricingProgram } from "../models/IPricingProgramType";
import { IDealDurationConfig } from "../models/IDealDurationConfig";
import _ from "lodash";
import { DealType } from "../models/DealType";
import { UserSubRole } from "../models/UserSubRole";
import { MessageType } from "../models/MessageType";
import { PartnerType } from "../models/PartnerType";
import { IDealRevertRequest } from "../models/IDealRevertRequest";
import { IDealExtensionConfig } from "../models/IDealExtensionConfig";

/**
 * NextGenService.
 * @class
*/
class NextGenService {
    /**
     * Method to Get Countries using API call.
     * @method
     * @returns {Promise<AxiosResponse<IProductType[]>>} The result of the call.
     */
    public async getAllCountries(): Promise<AxiosResponse<ICountry[]>> {
        return BaseService.axiosWrapper.get<ICountry[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.countryBase}${ApiConstants.getAllCountries}`);
    }

    /**
    * Method to Get Customer industries and verticals using API call.
    * @method
    * @returns {Promise<AxiosResponse<IIndustryDropdown[]>>} The result of the call.
    */
    public async getIndustries(): Promise<AxiosResponse<IIndustryDropdown[]>> {
        return BaseService.axiosWrapper.get<any>(
            `${BaseService.apiBaseUrl}${ApiConstants.customerBase}${ApiConstants.getCustomerIndustries}`);
    }

    /**
     * Method to Get ProductTypes using API call.
     * @method
     * @returns {Promise<AxiosResponse<IProductType[]>>} The result of the call.
     */
    public async getProductTypes(): Promise<AxiosResponse<IProductType[]>> {
        return BaseService.axiosWrapper.get<IProductType[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.productTypeBase}${ApiConstants.getAllProductType}`);
    }

    /**
     * Gets the list of opportunity deal quantity thresholds
     * @returns {Promise<AxiosResponse<IDealQuantityThresholds[]>>} Deal quantity thresholds array
     */
    public async getDealQuantityThresholds(): Promise<AxiosResponse<IDealQuantityThresholds[]>> {
        return BaseService.axiosWrapper.get<IDealQuantityThresholds[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDeal}${ApiConstants.getDealQuantityThresholds}`);
    }

    /**
     * Gets the list of opportunity deal duration configs
     * @returns {Promise<AxiosResponse<IDealDurationConfig[]>>} Deal duration config array
     */
    public async getDealDurationConfig(): Promise<AxiosResponse<IDealDurationConfig[]>> {
        return BaseService.axiosWrapper.get<IDealDurationConfig[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDeal}${ApiConstants.getDealDurationConfig}`);
    }

    /**
     * Extends opportunity deal
     * @param extensionRequest Extension request
     * @returns 
     */
    public async extendOpportunityDeal(extensionRequest: IDealExtensionRequest) {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDeal}${ApiConstants.extendOpportunityDeal}`, extensionRequest);
    }

    /**
     * Gets the deal extension count
     * @param opportunityDealId Opportunity deal Id
     * @returns Deal extension count
     */
    public async getDealExtensionConfig(opportunityDealId: string): Promise<AxiosResponse<IDealExtensionConfig>> {
        var params = new URLSearchParams();
        params.append("opportunityDealId", opportunityDealId)

        return BaseService.axiosWrapper.get<IDealExtensionConfig>(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDeal}${ApiConstants.extensionConfig}`, { params });
    }

    /**
     * Method to Post Opportunity Deal Status
     * @param opportunityDealId 
     * @param stateCode 
     * @param statusCode
     * @param fullName 
     * @param email 
     * @returns 
     */
    public async setOpportunityDealStatus(opportunityDealId: string, stateCode: number, statusCode: number, fullName: string, email: string) {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDeal}${ApiConstants.setOpportunityDealStatus}?opportunityDealId=${opportunityDealId}&stateCode=${stateCode}&statusCode=${statusCode}&fullName=${fullName}&email=${email}`);
    }

    /**
    * Method to Post  RevertOpportunity Deal Status
    * @param revertRequest 
    * @returns 
    */
    public async revertOpportunityDealStatus(revertRequest: IDealRevertRequest) {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityBase}${ApiConstants.revertOpportunityDealStatus}`, revertRequest, {
            headers: { 'Access-Control-Allow-Origin': '*' }
        });
    }

    /**
    * Method to Get Verticals using API call.
    * @method
    * @returns {Promise<AxiosResponse<IProductType[]>>} The result of the call.
    */
    public async getAllVerticals(): Promise<AxiosResponse<IVertical[]>> {
        return BaseService.axiosWrapper.get<IVertical[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.verticalBase}${ApiConstants.getAllVerticals}`);
    }

    /**
    * Method to Get Distributors Accounts using API call.
    * @method
    * @returns {Promise<AxiosResponse<IProductType[]>>} The result of the call.
    */
    public async getAllDistributors(): Promise<AxiosResponse<IAccount[]>> {
        var params = new URLSearchParams();
        params.append("partnerTypeRoles", PartnerType.ADD.toString())
        return BaseService.axiosWrapper.get<IAccount[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.accountsBase}${ApiConstants.getAllActiveDistributors}`,
            { params });
    }

    /**
    * Method to Get Resellers Accounts using API call.
    * @method
    * @returns {Promise<AxiosResponse<IProductType[]>>} The result of the call.
    */
    public async getAllResellers(): Promise<AxiosResponse<IAccount[]>> {
        return BaseService.axiosWrapper.get<IAccount[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.accountsBase}${ApiConstants.getAllActiveResellers}`);
    }

    /**
    * Method to Get All DealTypes using API call.
    * @method
    * @returns {Promise<AxiosResponse<IDealType[]>>} The result of the call.
    */
    public async getAllDealTypes(): Promise<AxiosResponse<IDealType[]>> {
        return BaseService.axiosWrapper.get<IDealType[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.dealTypeBase}${ApiConstants.getAllDealTypes}`);
    }

    /**
    * Method to Get All the resource strings.
    * @method
    * @returns {[Key: string]: {[Key: string]: {[Key: string]: string}}} The result of the call.
    */
    public async getAllResourceStrings(): Promise<AxiosResponse<{ [Key: string]: { [Key: string]: { [Key: string]: string } } }>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.ruleValidationBase}${ApiConstants.getCustomMessageAndText}`,
            [MessageType.OpportunityValidationError, MessageType.FieldGuidanceText, MessageType.FieldPlaceHolderText, MessageType.FieldLabelText, MessageType.PopupMessageText],
            {
                headers: { 'Access-Control-Allow-Origin': '*' },
            });
    }

    /**
     * Method to Get Resellers Accounts using API call.
     * @method
     * @returns {Promise<AxiosResponse<IAccount[]>>} The result of the call.
     */
    public async getAllActiveResellersByProductCountriesAndType(productType: string, countryIds: string[]): Promise<AxiosResponse<IAccount[]>> {
        var params = new URLSearchParams();
        countryIds.forEach((countryId: string) => params.append("countryIds", countryId));
        return BaseService.axiosWrapper.get<IAccount[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.accountsBase}${ApiConstants.GetAllActiveResellersByProductAndCountries}/${productType}`,
            { params });
    }

    /**
     * Method to Get Resellers Accounts for a given Product and Country using API call.
     * @method
     * @returns {Promise<AxiosResponse<IAccount[]>>} The result of the call.
     */
    public async getAllActiveResellersByProductAndCountry(productType: string, countryId: string): Promise<AxiosResponse<IAccount[]>> {
        return BaseService.axiosWrapper.get<IAccount[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.accountsBase}${ApiConstants.getAllActiveResellersByProductAndCountry}?productTypeId=${productType}&countryId=${countryId}`);
    }

    /**
   * Method to Get all Distributors for a given DMP account id and producttype.
   * @method
   * @returns {Promise<AxiosResponse<IAccount[]>>} The result of the call.
   */
    public async getAddsAccountsByDmpAccountIds(productType: string, accounIds: string[]): Promise<AxiosResponse<{ [key: string]: IAccount[]; }>> {
        var params = new URLSearchParams();
        params.append("productTypeId", productType);
        accounIds.forEach((accountId: string) => params.append("accounIds", accountId));
        return BaseService.axiosWrapper.get<{ [key: string]: IAccount[]; }>(
            `${BaseService.apiBaseUrl}${ApiConstants.accountsBase}${ApiConstants.GetAddsAccountsByDmpAccountIds}`,
            { params });
    }

    /**
     * Method to Get Microsoft Contact details from grpah API.
     * @method
     * @param email {string}
     * @returns {Promise<AxiosResponse<IContact>>} The result of the call.
     */
    public async getMicrosoftContactByAlias(email: string): Promise<AxiosResponse<IContact>> {
        return BaseService.axiosWrapper.get<IContact>(
            `${BaseService.apiBaseUrl}${ApiConstants.contactBase}${ApiConstants.getContactFromGraphByEmail}/${email}`);
    }

    /**
     * Method to Get OptionsetValue of Contact Role.
     * @method
     * @param email {string}
     * @returns {Promise<AxiosResponse<IKeyValue[]>>} The result of the call.
     */
    public async getContactRoleOptions(): Promise<AxiosResponse<IKeyValue[]>> {
        return BaseService.axiosWrapper.get<IKeyValue[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.contactBase}${ApiConstants.getContactRoleOptions}`);
    }

    /**
     * Method to Get draft opportunity from server and set in the state.
     * @method
     * @param oppNumber {string}
     * @returns {Promise<AxiosResponse<ICPStrategicState>>} The result of the call.
     */
    public async getOpportunity(opportunityNumber: string, userRoleDetails: IUserRoleDetail[], pageMode: string): Promise<AxiosResponse<IPageState>> {
        const params = new URLSearchParams();
        let countries = "";
        let accounts = "";
        countries = _.uniq(userRoleDetails.map((userRoleDetail) => userRoleDetail.countryId)).join(',');
        accounts = _.uniq(userRoleDetails.map((userRoleDetail) => userRoleDetail.accountId)).join(',');
        let userRole = getDistinctUserRole(userRoleDetails);
        sessionStorage.setItem(SessionStorageConsts.userRole, userRole.toString());
        params.append("countries", countries);
        params.append("accounts", accounts);
        params.append("userRole", userRole);
        return BaseService.axiosWrapper.get<IPageState>(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityBase}${ApiConstants.getOpportunity}/${opportunityNumber}`, { params });
    }

    /**
     * Method to Get opportunity from server and set in the state.
     * @method
     * @param oppNumber {string}
     * @returns {Promise<AxiosResponse<IPageState>>} The result of the call.
     */
    public async getOpportunityCommon(opportunityNumber: string, userRoleDetails: IUserRoleDetail[], selectedDealCaseNumber: string): Promise<AxiosResponse<IPageState>> {
        const params = new URLSearchParams();
        let countries = "";
        let accounts = "";
        countries = userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.countryId }).join(',');
        accounts = userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.accountId }).join(',');
        let userRole = getDistinctUserRole(userRoleDetails);
        sessionStorage.setItem(SessionStorageConsts.userRole, userRole.toString());
        params.append("countries", countries);
        params.append("accounts", accounts);
        params.append("userRole", userRole);
        if (!_.isEmpty(selectedDealCaseNumber)) {
            params.append("selectedDealCaseNumber", selectedDealCaseNumber);
        }
        return BaseService.axiosWrapper.get<IPageState>(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityBase}${ApiConstants.getOpportunity}/${opportunityNumber}`, { params });
    }

    /**
     * Method to save draft opportunity to server.
     * @method
     * @param opportunity {IPageState}
     * @returns {Promise<AxiosResponse<string>>} Saved Opportunity Name.
     */
    public async saveDraftOpportunity(opportunity: IPageState): Promise<AxiosResponse<string>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityBase}${ApiConstants.saveDraftOpportunity}`, opportunity, {
            headers: { 'Access-Control-Allow-Origin': '*' },
        });
    }

    /**
     * Method to save opportunity to server.
     * @method
     * @param opportunity {IPageState}
     * @returns {Promise<AxiosResponse<string>>} Saved Opportunity Name.
     */
    public async submitOpportunity(opportunity: IPageState): Promise<AxiosResponse<string>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityBase}${ApiConstants.saveOpportunity}`, opportunity, {
            headers: { 'Access-Control-Allow-Origin': '*' },
        });
    }

    /**
     * Method to save async opportunity to server.
     * @method
     * @param opportunity {IPageState}
     * @returns {Promise<AxiosResponse<string>>} Saved Opportunity Name.
     */
    public async submitOpportunityAsync(opportunity: IPageState): Promise<AxiosResponse<string>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityBase}${ApiConstants.saveOpportunityAsync}`, opportunity, {
            headers: { 'Access-Control-Allow-Origin': '*' },
        });
    }

    /**
     * Method to Get Customer Search Results using API call.
     * @method
     * @returns {Promise<AxiosResponse<IFacetData[]>>} The result of the call.
    */
    public async getCustomerSearchResults(filterString: string, selectedSearchValue: string, skipValue: number, allFacetNames: string[], top?: number): Promise<AxiosResponse<any[]>> {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'api-key': process.env.REACT_APP_SEARCH_API_KEY || '',
            }
        };

        return BaseService.axiosWrapper.post(
            process.env.REACT_APP_SEARCH_API_URL_ENV + ApplicationConstants.CustomerSearchIndexApiUrl || '',
            {
                "count": true,
                "facets": allFacetNames,
                "filter": filterString,
                "search": selectedSearchValue,
                "skip": skipValue,
                "searchMode": ApplicationConstants.CognitiveSearchMode,
                "top": top != null ? top : 50
            },
            config
        );
    }

    /**
    * Method to Get Customer Search Results for Pricing Program using API call.
    * @method
    * @returns {Promise<AxiosResponse<IFacetData[]>>} The result of the call.
    */
    public async getPricingProgramAllDistinctCustomerSearchResults(filterString: string, selectedSearchValue: string, skipValue: number, searchMode: string): Promise<AxiosResponse<any[]>> {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'api-key': process.env.REACT_APP_SEARCH_API_KEY || '',
            }
        };

        return BaseService.axiosWrapper.post(

            process.env.REACT_APP_SEARCH_API_URL_ENV + ApplicationConstants.PricingProgramAllDistinctCustomerSearchIndexApiUrl || '',
            {
                "count": true,
                "filter": filterString,
                "search": selectedSearchValue,
                "skip": skipValue,
                "searchMode": searchMode,
            },
            config
        );
    }

    /**
    * Method to Get User Details .
    * @method
    * @param email {string}
    * @returns {Promise<AxiosResponse<IUserDetail>>} The result of the call.
    */
    public async getUserDetails(userDetail: IUserDetail): Promise<AxiosResponse<IUserDetail>> {
        let headers: any = { 'Access-Control-Allow-Origin': '*' };
        if (process.env.REACT_APP_AUTHORIZATION_ENABLED) {
            headers['Authorization'] = `Bearer ${sessionStorage.getItem("userToken")}`;
        }
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.userBase}${ApiConstants.getUserDetails}`,
            userDetail,
            { headers }
        );
    }

    /**
    * Method to getAccountDetails .
    * @method
    * @param bearerToken {string}
    * @param userToken {string}
    * @returns {Promise<AxiosResponse<any>>} The result of the call ??
    */
    public async getUserAccountDetails(userEmail: string): Promise<AxiosResponse<any>> {
        let headers: any = { 'Access-Control-Allow-Origin': '*' };
        if (process.env.REACT_APP_AUTHORIZATION_ENABLED) {
            headers['Authorization'] = `Bearer ${sessionStorage.getItem("userToken")}`;
        }
        return BaseService.axiosWrapper.get<string>(
            `${BaseService.apiBaseUrl}${ApiConstants.userBase}${ApiConstants.getAccountDetails}?userEmail=${userEmail}`,
            { headers }
        );
    }

    /**
    * Method to Get Chatbot User Role .
    * @method
    * @param userEmail {string}
    * @returns {Promise<AxiosResponse<string>>} The result of the call.
    */
    public async getChatbotUserRole(userEmail: string): Promise<AxiosResponse<string>> {
        return BaseService.axiosWrapper.get<string>(
            `${BaseService.apiBaseUrl}${ApiConstants.userBase}${ApiConstants.getChatbotUserRole}?userEmail=${userEmail}`);
    }

    /**
     * Method to Get CPStrategicLetters  Based on OpportunityDealId using API call.
     * @method
     * @param opportunityDealId 
     * @param partnerRole 
     * @returns {Promise<AxiosResponse<IAttachment[]>>} The result of the call.
     */
    public async getCPStrategicLettersByOpportunityDealId(opportunityDealId: string, partnerRole: string): Promise<AxiosResponse<IAttachment[]>> {
        return BaseService.axiosWrapper.get<IAttachment[]>(
            `${BaseService.apiBaseUrl}${ApiConstants.attachmentsBase}${ApiConstants.getCPStrategicLettersByOpportunityDealId}?opportunityDealId=${opportunityDealId}&partnerRole=${partnerRole}`
        )
    }

    /**
    * Method to Get list of opportunity deal from server.
    * @method
    * @param dashboardDataRequest {IDashboardDataRequest}
    * @returns {Promise<AxiosResponse<Array<IOpportunityDeal>>} The result of the call.
    */
    public async getDashboardData(dashboardDataRequest: IDashboardDataRequest): Promise<AxiosResponse<IOpportunityDeal[]>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDeal}${ApiConstants.GetDashboardData}`, dashboardDataRequest);
    }

    /**
    * Method to Validate Case Id and to get valid Resellers.
    * @param validateCaseAndResellerRequest {IValidateCaseAndResellerRequest}
    * @returns {Promise<AxiosResponse<IValidateOpportunityResponse>>} The result of the call.
    */
    public async validateCaseAndReseller(validateCaseAndResellerRequest: IValidateCaseAndResellerRequest): Promise<AxiosResponse<IValidateOpportunityResponse>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDeal}${ApiConstants.validateOpportunityNameAndReseller}`, validateCaseAndResellerRequest, {
            headers: { 'Access-Control-Allow-Origin': '*' },
        });
    }

    /**
     * Method to Post Opportunity Deal Status
     * @param opportunityDealId {string}
     * @param addOpportunityAccountToCaseRequest {IAddOpportunityAccountToCaseRequest}
     * @returns {Promise<AxiosResponse<IDataSaveResponse>>}
     */
    public async AddOpportunityAccountToCase(opportunityId: string, addOpportunityAccountToCaseRequest: IAddOpportunityAccountToCaseRequest): Promise<AxiosResponse<IDataSaveResponse>> {
        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                "content-type": "application/json",
            }
        };
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityAccountBase}${ApiConstants.addOpportunityAccountToCase}?opportunityId=${opportunityId}`, addOpportunityAccountToCaseRequest, config);
    }
    /**
   * Method to Validate Deal Customers .
   * @method
   * @param email {string}
   * @returns {Promise<AxiosResponse<string>>} The result of the call.
   */
    public async validateDealCustomers(opportunity: IPageState): Promise<AxiosResponse<string>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityBase}${ApiConstants.validateCustomers}`, opportunity, {
            headers: { 'Access-Control-Allow-Origin': '*' },
        });
    }

    /**
  * Method to GetOpportunityDealDiscountPeriodAndSkuMSRP .
  * @method
  * @param opportunityDealId {string}
  * @returns {Promise<AxiosResponse<string>>} The result of the call.
  */
    public async getOpportunityDealDiscountPeriodAndSkuMSRP(opportunityDealId: string, userRoleDetails: IUserRoleDetail[], pageMode: string, dealType: string): Promise<AxiosResponse<IOpportunityDealProductSku[]>> {
        if (pageMode.toLowerCase() === PageMode.View.toLowerCase()) {
            var countries = null;
            if (IsInternalPricingProgram(dealType)) {
                countries = Array.from(new Set(userRoleDetails.filter(x => x.isLoggedInUserOrg == true).map(function (userRoleDetail) { return userRoleDetail.countryId }))).join(',');
            }
            else {
                countries = Array.from(new Set(userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.countryId }))).join(',');
            }
            let accounts = Array.from(new Set(userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.accountId }))).join(',');
            let userRole = getDistinctUserRole(userRoleDetails);
            const params = new URLSearchParams();
            params.append("countries", countries);
            params.append("accounts", accounts);
            params.append("userRole", userRole);
            return BaseService.axiosWrapper.get<IOpportunityDealProductSku[]>(
                `${BaseService.apiBaseUrl}${ApiConstants.opportunityDealProductSkuBase}${ApiConstants.getOpportunityDealDiscountPeriodAndSkuMSRP}?opportunityDealId=${opportunityDealId}`, { params });
        }
        else {
            return BaseService.axiosWrapper.get<IOpportunityDealProductSku[]>(
                `${BaseService.apiBaseUrl}${ApiConstants.opportunityDealProductSkuBase}${ApiConstants.getOpportunityDealDiscountPeriodAndSkuMSRP}?opportunityDealId=${opportunityDealId}`);
        }
    }

    /**
    * Method to Get Business Vertical from Customer Vertical using API call.
    * @param vertical
    * @returns 
    */
    public async getBusinessVertical(customerVertical: ICustomerVertical): Promise<AxiosResponse<IVertical>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.verticalBase}${ApiConstants.businessVertical}`,
            customerVertical
            , {
                headers: { 'Access-Control-Allow-Origin': '*' },
            });
    }

    /**
 * Method to Get CaseDecisionLetter  Based on OpportunityDealId using API call.
 * @method
 * @param opportunityDealId 
 * @param partnerRole 
 * @returns {Promise<AxiosResponse<>>} The result of the call.
 */
    public async getCaseLetters(opportunityDealId: string, userRoleDetails: IUserRoleDetail[], pricingProgram: string, dashboardDataRequest: any, isSKUsRequired = true) {
        const params = new URLSearchParams();
        var userRole = getUserRole();
        var userSubRole = _.uniq(userRole.split(",")).join(",");
        if (userSubRole.includes(UserSubRole.MSStoreUser)) {
            userSubRole = UserSubRole.MSStoreUser
        } else {
            userSubRole = userSubRole.split(",")[0]
        }
        params.append("userRole", userSubRole);
        params.append("pricingprogram", `${pricingProgram}`);
        params.append("isSKUsRequired", `${isSKUsRequired}`);
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.caseLetters}${ApiConstants.getCaseDecisionLetter}?opportunityDealId=${opportunityDealId}&${params}`, dashboardDataRequest);
    }

    /**
     * Method to save customer to server.
     * @method
 * @param createUnmanagedCustomer {IUnmanagedCustomer}
 * @returns {Promise<AxiosResponse<IDataSaveResponse>>} .
 */
    public async createUnmanagedCustomer(createUnmanagedCustomer: IUnmanagedCustomer): Promise<AxiosResponse<IUnmanagedCustomerResult>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDealCustomerBase}${ApiConstants.createUnmanagedCustomer}`, createUnmanagedCustomer, {
            headers: { 'Access-Control-Allow-Origin': '*' },
        });
    }

    /**
     * Method to update customer to server.
     * @method
     * @param updateUnmanagedCustomer {IUnmanagedCustomer}
     * @returns {Promise<AxiosResponse<IDataSaveResponse>>} .
     */
    public async updateUnmanagedCustomer(updateUnmanagedCustomer: IUnmanagedCustomer): Promise<AxiosResponse<IUnmanagedCustomerResult>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDealCustomerBase}${ApiConstants.updateUnmanagedCustomer}`, updateUnmanagedCustomer, {
            headers: { 'Access-Control-Allow-Origin': '*' },
        });
    }

    /**
     * Method to search DuplicateDeal
     * @param pricingProgramDuplicateDeal 
     * @returns object
     */
    public async searchDuplicateDeal(pricingProgramDuplicateDeal: IPricingProgramDuplicateDeal) {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDeal}${ApiConstants.searchDuplicateDeal}`,
            pricingProgramDuplicateDeal
            , {
                headers: { 'Access-Control-Allow-Origin': '*' },
            });
    }

    /**
     * Method to Get Customer Search Results for Pricing Program using API call.
     * @method
     * @returns {Promise<AxiosResponse<IFacetData[]>>} The result of the call.
    */
    public async getPricingProgramCustomerSearchResults(filterString: string, selectedSearchValue: string, skipValue: number, allFacetNames: string[], searchMode: string, top?: number): Promise<AxiosResponse<any[]>> {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'api-key': process.env.REACT_APP_SEARCH_API_KEY || '',
            }
        };

        return BaseService.axiosWrapper.post(
            process.env.REACT_APP_SEARCH_API_URL_ENV + ApplicationConstants.PricingProgramCustomerSearchIndexApiUrl || '',
            {

                "count": true,
                "facets": allFacetNames,
                "filter": filterString,
                "search": selectedSearchValue,
                "skip": skipValue,
                "searchMode": searchMode,
                "top": top != null ? top : 50
            },
            config
        );
    }

    /**
     * Method to Get list of opportunity deal from server.
     * @method
     * @param dashboardDataRequest {IDashboardDataRequest}
     * @returns {Promise<AxiosResponse<Array<IPricingProgram>>} The result of the call.
     */
    public async getPricingProgramDashboardData(dashboardDataRequest: IDashboardDataRequest): Promise<AxiosResponse<IPricingProgram>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDeal}${ApiConstants.getPricingProgramDashboardData}`, dashboardDataRequest);
    }

    /**
     * Clones opportunity deal
     * @param copyRequest copy request
     * @returns 
     */
    public async copyOpportunity(copyRequest: IDealCopyRequest): Promise<AxiosResponse<string>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityBase}${ApiConstants.copyOpportunity}`, copyRequest, {
            headers: { 'Access-Control-Allow-Origin': '*' }
        });
    }

    /**
     * Renews opportunity deal
     * @param renewRequest renew request
     * @returns 
     */
    public async renewOpportunity(renewRequest: IDealActionRequest): Promise<AxiosResponse<string>> {
        if (renewRequest.dealTypeName !== DealType.CPStrategic) {
            const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
            let countries = "";
            let accounts = "";
            countries = userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.countryId }).join(',');
            accounts = userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.accountId }).join(',');
            let userRole = getDistinctUserRole(userRoleDetails);
            sessionStorage.setItem(SessionStorageConsts.userRole, userRole.toString());
            renewRequest.countries = countries;
            renewRequest.accounts = accounts;
            renewRequest.userRole = userRole;
        }

        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityBase}${ApiConstants.renewOpportunity}`, renewRequest, {
            headers: { 'Access-Control-Allow-Origin': '*' }
        });
    }

    /**
    * Method to Get list of opportunity deal from server.
    * @method
    * @param dashboardDataRequest {IDashboardDataRequest}
    * @returns {Promise<AxiosResponse<Array<IBidGridDeal>>} The result of the call.
    */
    public async getBidGridDashboardData(dashboardDataRequest: IDashboardDataRequest): Promise<AxiosResponse<IBidGridDeal[]>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.bidGridTypeBase}${ApiConstants.getBidGridDashboardData}`, dashboardDataRequest);
    }

    /**
    * Method to Get list of ProductInformation  from server.
    * @method
    * @param dashboardDataRequest {IDashboardDataRequest}
    * @returns {Promise<AxiosResponse<Array<IOpportunityDealProductSku>>} The result of the call.
    */
    public async getProductinformationDashboardData(dashboardDataRequest: IDashboardDataRequest): Promise<AxiosResponse<IOpportunityDealProductSku[]>> {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.opportunityDealProductSkuBase}${ApiConstants.getAllSpecialPricingProducts}`, dashboardDataRequest);
    }

    /**
     * Method to Get Blob Array buffer as string.
     * @method
     * @returns {Promise<AxiosResponse<string>>} The result of the call.
     */
    public async getDocumentFromBlob(blobName: string): Promise<AxiosResponse<string>> {
        return BaseService.axiosWrapper.get<string>(
            `${BaseService.apiBaseUrl}${ApiConstants.blobBase}${ApiConstants.getBlobBufferByName}?name=${blobName}`);
    }

    /**
     * Method to Get Product Search Results using API call.
     * @method
     * @returns {Promise<AxiosResponse<IFacetData[]>>} The result of the call.
    */
    public async getProductSearchResults(url: string, filterString: string, selectedSearchValue: string, skipValue: number, allFacetNames: string[], top?: number): Promise<AxiosResponse<any[]>> {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'api-key': process.env.REACT_APP_SEARCH_API_KEY || '',
            }
        };

        return BaseService.axiosWrapper.post(
            url || '',
            {
                "count": true,
                "facets": allFacetNames,
                "filter": filterString,
                "search": selectedSearchValue,
                "skip": skipValue,
                "searchMode": ApplicationConstants.CognitiveSearchMode,
                "top": top != null ? top : 50
            },
            config
        );
    }

    /**
     * Method to Post the Rating
     * @param userVoice 
     * @returns boolean
     */
    public async saveUserVoice(userVoice: IUserVoice) {
        return BaseService.axiosWrapper.post(
            `${BaseService.apiBaseUrl}${ApiConstants.userVoiceBase}${ApiConstants.saveUserVoice}`,
            userVoice
            , {
                headers: { 'Access-Control-Allow-Origin': '*' },
            });
    }
}

export const nextGenService = new NextGenService();